import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  let location;
  try {
    location = useLocation();
  } catch (e) {}

  const getQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);

    const params = {};
    queryParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  };

  return [getQueryParams];
};
