import { OptionInterface } from '@veneer/core/dist/scripts/contextual_menu';
import { TablePreferences } from '@veneer/core/dist/scripts/table';

export const dateFormat = {
  default: 'lll', // March 17th, 2022 02:45:30 PM (12 hours format)
  app_config: 'MMDDYYYY_HHMM',
  added_date: 'MMM DD, YYYY',
  policy_lastRun: 'lll',
};

export const service = {
  collection: 'collection',
  accountMgt: 'accountMgt',
  commercePortal: 'commercePortal',
  deviceCache: 'deviceCache',
  deviceShadow: 'deviceShadow',
  fleetMgt: 'fleetMgt',
  programMgt: 'programMgt',
  solutionRegistry: 'solutionRegistry',
  subscriptions: 'subscriptions',
  telemetry: 'telemetry',
  appDeploy: 'appDeploy',
  fleetProxy: 'fleetProxy',
};

export const solutionID = {
  secureFleetManger: 'serviceId: ws-hp.com/smcloud',
};

export const store = {
  devices: {
    START_ROOT_COMPONENT: 'devices/startRootComponent',
    IS_DEVICES_TAB: 'devices/isDevicesTab',
    IS_NAVIGATED_FROM_DETAILS: 'devices/isFromDetails',
    IS_FROM_UNASSIGNED: 'devices/isFromUnassigned',
    DEVICE_TABLE_REFRESH: 'devices/deviceTableRefresh',
    RESET_DEVICES_REDUCER: 'devices/reset',

    STORE_INITIAL_COLUMNS: 'devices/storeInitialColumns',
    STORE_TABLE_FILTER: 'devices/storeTableFilter',
    STORE_TABLE_FILTER_COUNT: 'devices/storeTableFilterCount',
    STORE_TABLE_SORT_ID: 'devices/storeTableSortId',
    STORE_TABLE_SORT_TYPE: 'devices/storeTableSortType',
    STORE_TABLE_SORT_CLICKED: 'devices/storeTableSortClicked',

    STORE_TABLE_DATA: 'devices/storeTableData',
    STORE_TOTAL_DEVICE_COUNT_IN_GROUP: 'devices/storeTotalDeviceCountInGroup',
    STORE_PAGED_DATA: 'devices/storePagedData',
    STORE_SELECTED_ITEMS: 'devices/storeSelectedItems',
    STORE_COLUMN_CONTENTS: 'devices/storeColumnContents',
    STORE_SEARCH_ITEM: 'devices/storeSearchItem',
    STORE_CURRENT_PAGE: 'devices/storeCurrentPage',
    STORE_PAGE_SIZE: 'devices/storePageSize',
    STORE_API_REQUEST_OPTIONS: 'devices/storeApiRequestOptions',
  },
  devicesGroup: {
    // Common
    ALL_DEVICES_GROUP_UUID: 'devicesGroup/allDevicesGroupUuid',
    UNGROUPED_GROUP_UUID: 'devicesGroup/ungroupedGroupUuid',

    // Main Group
    MAIN_GROUP_DATA: 'devicesGroup/mainGroupData',
    MAIN_GROUP_SELECTED_ID: 'devicesGroup/mainGroupSelectedId',
    MAIN_GROUP_SELECTED_NAME: 'devicesGroup/mainGroupSelectedName',
    MAIN_GROUP_REFRESH: 'devicesGroup/mainGroupRefresh',
    MAIN_GROUP_DISABLE: 'devicesGroup/mainGroupDisable',

    // Move Modal Group
    MOVE_GROUP_MODAL_DATA: 'devicesGroup/moveGroupModalData',
    MOVE_GROUP_MODAL_SELECTED_ID: 'devicesGroup/moveGroupModalSelectedId',
    MOVE_GROUP_MODAL_SELECTED_NAME: 'devicesGroup/moveGroupModalSelectedName',

    // Responsive Group
    FLEX_SHOW_MAIN_GROUP_MODAL: 'devicesGroup/showMainGroupModal',

    //WEX
    SHOW_SIDE_BAR: 'devicesGroup/showSideBar',

    //Reset to init state
    RESET_DEVICE_GROUP_REDUCER: 'devicesGroup/reset',
  },
  devicesRnPm: {
    STORE_PERMISSION_COLLECTIONS_CREATE: 'devicesRnPm/collectionsCreate',
    STORE_PERMISSION_COLLECTIONS_READ: 'devicesRnPm/collectionsRead',
    STORE_PERMISSION_COLLECTIONS_UPDATE: 'devicesRnPm/collectionsUpdate',
    STORE_PERMISSION_COLLECTIONS_DELETE: 'devicesRnPm/collectionsDelete',
    STORE_PERMISSION_CONTENTS_DEVICES_CREATE: 'devicesRnPm/contentsDevicesCreate',
    STORE_PERMISSION_CONTENTS_DEVICES_READ: 'devicesRnPm/contentsDevicesRead',
    STORE_PERMISSION_CONTENTS_DEVICES_COUNT: 'devicesRnPm/contentsDevicesCount',
    STORE_PERMISSION_CONTENTS_DEVICES_UPDATE: 'devicesRnPm/contentsDevicesUpdate',
    STORE_PERMISSION_CONTENTS_COLLECTIONS_CREATE: 'devicesRnPm/contentsCollectionsCreate',
    STORE_PERMISSION_CONTENTS_COLLECTIONS_READ: 'devicesRnPm/contentsCollectionsRead',
    STORE_PERMISSION_REPORTS_OWNER: 'devicesRnPm/reportsOwner',
    STORE_PERMISSION_MEMBERSHIPS_COLLECTIONS_UPDATE: 'devicesRnPm/membershipsCollectionsUpdate',
    STORE_PERMISSION_PARTICIPANTINFOS_DELETE: 'devicesRnPm/participantinfosDelete',
    STORE_PERMISSION_DEVICES_DELETE: 'devicesRnPm/devicesDelete',

    RESET_DEVICES_RNPM_REDUCER: 'devicesRnPm/reset',
  },
};

export const permission = {
  collections: {
    CREATE: 'ws-hp.com/collections/collections.CREATE',
    READ: 'ws-hp.com/collections/collections.READ',
    UPDATE: 'ws-hp.com/collections/collections.UPDATE',
    DELETE: 'ws-hp.com/collections/collections.DELETE',
  },
  contents: {
    devices: {
      CREATE: 'ws-hp.com/collections/contents.devices.CREATE',
      READ: 'ws-hp.com/collections/contents.devices.READ',
      COUNT: 'ws-hp.com/collections/contents.devices.COUNT',
      UPDATE: 'ws-hp.com/collections/contents.devices.UPDATE',
    },
    collections: {
      CREATE: 'ws-hp.com/collections/contents.collections.CREATE',
      READ: 'ws-hp.com/collections/contents.collections.READ',
    },
  },
  reports: {
    OWNER: 'ws-hp.com/smcloud/reports.OWNER',
  },
  memberships: {
    devices: {},
    collections: {
      UPDATE: 'ws-hp.com/collections/memberships.collections.UPDATE',
    },
  },
  participantinfos: {
    DELETE: 'ws-hp.com/programmgtsvc/companies/participantinfos.DELETE',
  },
  devices: {
    DELETE: 'ws-hp.com/accountmgtsvc/devices.DELETE',
  },
};

export const TABLE_ID = {
  DEVICES_TABLE: 'devices-table',
  SOLUTIONS_TABLE: 'solutions-table',
  APPS_TABLE: 'apps-table',
};

const origin = {
  ECP_LOCAL: 'http://ecp.local',
  DEV: 'https://ecp.dev.portalshell.int.hp.com',
  PIE: 'https://ecp.pie.portalshell.int.hp.com',
  STAGE: 'https://ecp.stage.portalshell.int.hp.com',
  PROD: 'https://hp-commandcenter.com',
  WX_LOCAL: 'http://localhost:3000',
  WX_STABLE: 'https://usdevms-workforce.hppipeline.com',
  WX_STAGE: 'https://usstagingms.workforceexperience.hp.com',
  WX_PROD: 'https://workforceexperience.hp.com',
};

export const currentStack = {
  DEV: window.location.origin === origin.DEV,
  LOCAL:
    window.location.origin.includes(origin.ECP_LOCAL) ||
    window.location.origin.includes(origin.WX_LOCAL),
  PIE: window.location.origin === origin.PIE || window.location.origin === origin.WX_STABLE,
  STAGE: window.location.origin === origin.STAGE || window.location.origin === origin.WX_STAGE,
  PROD: window.location.origin === origin.PROD || window.location.origin === origin.WX_PROD,
};

/**
 * Table
 */

export const filterOptions = {
  connectionState: '',
  outputLevel: 'minimal',
  sortBy: 'connectionState',
  order: 'descending',
  offset: 0,
  limit: 25,
  search: '',
  recursiveExpansion: true,
};

export const fullListOption = {
  limit: -1,
  offset: 0,
};

export const defaultPageSizeOptions: OptionInterface<number>[] = [
  { value: 5 },
  { value: 25 },
  { value: 50 },
  { value: 100 },
  { value: 500 },
];

export const preferences: TablePreferences = {
  sortBy: {
    id: 'connectionState',
    type: 'descending',
  },
};

export enum ComplianceType {
  Unknown = 'unknown',
  Compliant = 'compliant',
  Overridden = 'overridden',
  NonCompliant = 'noncompliant',
  NO_DATA = '--',
}

export enum DeviceTableRefreshType {
  NONE,
  REFRESH_WITH_API_CALL,
  REFRESH_WITHOUT_API_CALL,
}

export const COLUMN_LABEL = {
  modelName: 'table.model_name',
  status: 'table.status',
  statusUpdated: 'table.status_updated',
  apps: 'table.apps',
  connectionState: 'table.connectivity',
  serialNumber: 'table.serial_number',
  wiredIPv4: 'table.wired_IPv4',
  wiredIPv6: 'table.wired_IPv6',
  wiredMAC: 'table.wired_MAC',
  wiredHostname: 'table.wired_hostname',
  wifiIPv4: 'table.wireless_IPv4',
  wifiIPv6: 'table.wireless_IPv6',
  wifiMAC: 'table.wireless_MAC',
  wifiHostname: 'table.wireless_hostname',
  wifiDirectIPv4: 'table.wifi_direct_IPv4',
  wifiDirectIPv6: 'table.wifi_direct_IPv6',
  wifiDirectMAC: 'table.wifi_direct_MAC',
  wifiDirectHostname: 'table.wifi_direct_hostname',
  addedDate: 'table.added_data',
  assessment: 'table.assessment',
  assetNumber: 'table.asset_number',
  firmwareVersion: 'table.firmware_version',
  deviceName: 'table.device_name',
  group: 'table.group',
  lastSyncTime: 'table.last_synced',
  location: 'table.location',
  manufacturer: 'table.manufacturer',
  policies: 'table.policies',
  policyCompliance: 'table.policy_compliance',
  policyLastRun: 'table.policy_LastRun',
  connectivityTypes: 'table.connectivity_types',
  deviceStatusMessage: 'table.device_status_message',
  uid: 'ID',
};
