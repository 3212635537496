import styled from 'styled-components';
import tokens from '@veneer/tokens';
import { Checkbox } from '@veneer/core';
import React from 'react';
import { t } from 'src/utils/commonMethods';

const FilterItem = (props: {
  filterLabel: string;
  columnId: string;
  filterItems: any;
  selectedFilter: any;
  handleFilterOption: (e) => void;
  showSearchResult: string;
}) => {
  const localizedFilterItems = props.filterItems.map((item) => t(`table.${item.toLowerCase()}`));
  const getInitialState = () => {
    if (!props.selectedFilter) return {};
    const parsedArray = JSON.parse(props.selectedFilter);
    const result: { [key: string]: string[] } = {};
    parsedArray.forEach(([key, values]) => {
      result[key] = values;
    });
    return result;
  };

  const checkedState = getInitialState();

  const searchShow = (item) => {
    return (
      props.showSearchResult === '' ||
      (props.showSearchResult !== '' &&
        item.toLowerCase().includes(props.showSearchResult.toLowerCase()))
    );
  };

  return (
    <>
      <HorizontalLineText>
        <span>{t(`table.${props.filterLabel.toLowerCase()}`)}</span>
        <div></div>
      </HorizontalLineText>
      <FilterCheckBoxContent>
        {localizedFilterItems ? (
          localizedFilterItems.map(
            (item, idx) =>
              searchShow(item) && (
                <Checkbox
                  label={item}
                  key={idx}
                  name={`${props.columnId}:${item}`}
                  checked={checkedState[props.columnId]?.includes(item) || false}
                  onChange={props.handleFilterOption}
                  customStyle={{ padding: '10px 0' }}
                />
              ),
          )
        ) : (
          <NoItems>{t('table.no_items')}</NoItems>
        )}
      </FilterCheckBoxContent>
    </>
  );
};

export default FilterItem;

const FilterCheckBoxContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const HorizontalLineText = styled.div`
  margin-top: 25px;

  span {
    display: flex;
    flex-direction: row;
    font-family: Forma DJR UI;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: ${tokens.color.gray4};
  }

  span::after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid ${tokens.color.gray3};
    margin: auto;
    margin-left: 5px;
  }
`;

const NoItems = styled.span`
  margin-left: 10px;
  color: ${tokens.color.gray4};
  font-weight: bold;
`;
