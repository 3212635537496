import styled from 'styled-components';
import React, { useMemo } from 'react';
import { t } from 'src/utils/commonMethods';
import { Button, IconSearch, Search } from '@veneer/core';
import { useStoreState } from 'src/store/useStoreState';
import { useTableHandler } from 'src/hooks/useTableHandler';
import { ResponsiveWrapper } from 'src/components/atom';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TTableSearchListProps = {
  disabled: any;
};

const TableSearchList = (props: TTableSearchListProps) => {
  const { isFromApps } = useShellRootContext();
  const { tableHandler } = useTableHandler();
  const { devicesGroupState, devicesState } = useStoreState();
  const { mainGroupSelectedName } = devicesGroupState;
  const { searchItem } = devicesState;
  const groupName =
    mainGroupSelectedName == 'All' || mainGroupSelectedName == 'Ungrouped'
      ? t('group.group_' + mainGroupSelectedName.toLowerCase())
      : mainGroupSelectedName;

  const i18nSearch = {
    clear: t('table.clear'),
    showingResult: t('table.showing_result'),
    showingResults: t('table.showing_results'),
  };

  const MobileButton = () => {
    return <Button appearance={'ghost'} leadingIcon={<IconSearch />} />;
  };

  const memoRender = useMemo(() => {
    return (
      <ResponsiveWrapper mobile={<MobileButton />}>
        <div id="search-box-section" data-testid="table-search-list">
          <Search
            key={mainGroupSelectedName}
            disabled={props.disabled}
            id="controlled-search"
            data-testid="search-box"
            defaultValue={searchItem}
            placeholder={t('table.search_device', { groupName: groupName })}
            maxLength={20}
            i18n={i18nSearch}
            onChange={(value) => {
              if (value != undefined && value.length >= 1) {
                tableHandler.handleSearch(value, isFromApps);
              }
              // refresh table in case of search value is empty.
              else if (value == undefined || value === '') {
                tableHandler.handleSearch('', isFromApps);
              }
            }}
            onClear={() => tableHandler.handleSearch('', isFromApps)}
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
        </div>
      </ResponsiveWrapper>
    );
  }, []);
  return <>{memoRender}</>;
};

const Form = styled.form`
  width: 272px;
  min-height: 36px;

  button:last-child {
    cursor: default;
  }
  button:last-child:hover {
    background: none;
    color: rgb(84, 84, 84);
  }
`;

export default TableSearchList;
