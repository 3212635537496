import { useShellRootContext } from 'src/contexts/ShellRoot';
import { t, checkIsFromSolutions } from 'src/utils/commonMethods';
import { COLUMN_LABEL } from 'src/utils/constants';

const useTableColumn = () => {
  const { columns, customRelativePath, featureFlags, customInitialColumns, isFromApps, isWex } =
    useShellRootContext();
  const unSupportedColumn = ['policies'];
  const initialColumns = [
    'serialNumber',
    'modelName',
    'status',
    'connectionState',
    'wiredIPv4',
    'addedDate',
  ];
  const initialColumns_WEX = [
    'serialNumber',
    'modelName',
    'assessment',
    'policies',
    'policyCompliance',
    'policyLastRun',
    'connectionState',
    'addedDate',
    'lastSyncTime',
    'deviceName',
    'group',
    'wiredIPv4',
  ];
  const column = (id, width, search?, required?, sortable?) => {
    return {
      id,
      label: t(COLUMN_LABEL[id]),
      width: width,
      search: search ?? false,
      required: required ?? false,
      sortable: sortable ?? true,
    };
  };

  const tableColumns: Array<any> = [
    column('modelName', 200, true, undefined),
    ...(!isWex ? [column('status', 200, true)] : []),
    ...(!isWex ? [column('statusUpdated', 220, true)] : []),
    column('apps', 130, true, undefined),
    column('connectionState', 130, true),
    column('serialNumber', 150, true, true),
    column('wiredIPv4', 220, true, !isFromApps),
    column('wiredIPv6', 220, true),
    column('wiredMAC', 220, true),
    column('wiredHostname', 220, true),
    column('wifiIPv4', 220, true),
    column('wifiIPv6', 220, true),
    column('wifiMAC', 220, true),
    column('wifiHostname', 220, true),
    column('wifiDirectIPv4', 220, true),
    column('wifiDirectIPv6', 220, true),
    column('wifiDirectMAC', 220, true),
    column('wifiDirectHostname', 220, true),
    column('addedDate', 220, true),
    column('assessment', 180, true),
    column('assetNumber', 180, true),
    column('firmwareVersion', 180, true),
    column('deviceName', 180),
    column('group', 180, true),
    column('lastSyncTime', 220, true),
    column('location', 180, true),
    column('manufacturer', 180, true),
    column('policies', 200, true),
    column('policyCompliance', 180, true),
    column('policyLastRun', 180, true),
    column('deviceStatusMessage', 220, true),
    ...(featureFlags.enableDeviceConnType ? [column('connectivityTypes', 180)] : []),
    { id: 'uid', label: 'ID', index: 'hidden', width: 10, sortable: false },
  ];

  const filteredColumnsFromSolutions = () => {
    return columns.filter((col) => !unSupportedColumn.includes(col));
  };

  const filteredCustomInitialColumns = () => {
    return customInitialColumns.filter((col) => !unSupportedColumn.includes(col));
  };

  const cfgInitialColumnsList = () => {
    if (checkIsFromSolutions(customRelativePath) && columns.length > 0) {
      return filteredColumnsFromSolutions();
    } else if (isFromApps && customInitialColumns.length > 0) {
      return filteredCustomInitialColumns();
    } else if (isWex) {
      return initialColumns_WEX;
    }
    return initialColumns;
  };

  const cfgColumnsList = () => {
    let filteredColumns = [...tableColumns];

    if ((checkIsFromSolutions(customRelativePath) || isFromApps) && columns.length > 0) {
      filteredColumns = filteredColumns.filter((col) => columns.includes(col.id));
    }

    return filteredColumns;
  };

  return {
    columnConfig: {
      cfgInitialColumnsList,
      cfgColumnsList,
    },
  };
};

export default useTableColumn;
