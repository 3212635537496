import styled from 'styled-components';
import React, { useState } from 'react';
import { FilterItem } from 'src/components/atom';
import { Button, Header, IconFunnel, Search, SideBar } from '@veneer/core';
import { t } from 'src/utils/commonMethods';

const TableFilter = (props: {
  handleFilterOption: (e) => void;
  selectedFilter: any;
  selectedFilterCount?: number;
  disabled?: boolean;
}) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showSearchResult, setShowSearchResult] = useState('');

  const connectivityFilter = ['Online', 'Offline'];
  const doHaveSelectedFilter = props.selectedFilterCount > 0;
  const handleShowSideBar = () => {
    showSideBar ? setShowSideBar(false) : setShowSideBar(true);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleFilterClick = () => {
    if (props.disabled) return;
    handleShowSideBar();
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    setShowSearchResult(searchValue);
  };

  return (
    <>
      <Button
        appearance="ghost"
        disabled={props.disabled}
        data-testid="table-filter-entry"
        onClick={handleFilterClick}
        leadingIcon={<IconFunnel filled={doHaveSelectedFilter} />}
      >
        <Label>
          {t('table.filter')} {doHaveSelectedFilter && <>({props.selectedFilterCount})</>}
        </Label>
      </Button>

      <SideBar
        id="filter-sidebar"
        data-testid={'filter-sidebar'}
        behavior="overlay"
        position="end"
        show={showSideBar}
        onClose={handleShowSideBar}
        disableBackdropClick
        hideBackdrop
        content={
          <FilterContent>
            <p id="filter-title">{t('table.filter')}</p>
            <p id="filter-subtitle">{t('table.filter_description')}</p>
            <Header
              id="filter-search"
              position="relative"
              leadingArea={
                <form onSubmit={handleSubmit} data-testid={'form-filter-search'}>
                  <Search
                    data-testid={'filter-search'}
                    id="controlled-search"
                    placeholder={t('table.search_filter')}
                    onChange={handleSearch}
                    onClear={() => setShowSearchResult('')}
                  />
                </form>
              }
            />
            <FilterItem
              filterLabel="Connectivity"
              columnId={'connectionState'}
              filterItems={connectivityFilter}
              handleFilterOption={props.handleFilterOption}
              selectedFilter={props.selectedFilter}
              showSearchResult={showSearchResult}
            />
          </FilterContent>
        }
      />
    </>
  );
};

export default TableFilter;

const Label = styled.span`
  font-family: Forma DJR UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

const FilterContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 40px 5px 20px;

  #filter-search > div {
    width: 255px;
  }

  #filter-title {
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: bold;
  }

  #filter-subtitle {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .margin-top20 {
    margin-top: 20px;
  }

  #create-filter-delete-button {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }
`;
