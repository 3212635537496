import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DeviceAppConfigTemplate } from 'src/components/template';
import useTableColumn from 'src/hooks/useTableColumn';
import { setTableColumns } from 'src/utils/commonMethods';
import { deviceCacheAPI } from 'src/api/deviceCache';
import { useStoreState } from 'src/store/useStoreState';
import {
  storeDeviceTableRefresh,
  storeSortById,
  storeSortByType,
  storeTableData,
  storeTableSortClicked,
  storeTotalDeviceCountInGroup,
} from 'src/store/devices/action';
import { useDispatch } from 'react-redux';
import { useTableHandler } from 'src/hooks/useTableHandler';
import { useSearch } from 'src/hooks/useSearch';
import { InitialLoading } from 'src/components/molecule';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { DeviceTableRefreshType, fullListOption } from 'src/utils/constants';
import { fetchAllWithLoop } from 'src/utils/groupMethods';
import { fleetProxyAPI } from 'src/api/fleetProxy';
import { useRefreshHook } from 'src/hooks/useRefreshHook';
import { useSort } from 'src/hooks/useSort';
import { useFilter } from 'src/hooks/useFilter';

const DeviceAppConfigPage = () => {
  const { devicesState, devicesRnPmState } = useStoreState();
  const { appUuid } = useShellRootContext();
  const {
    deviceTableRefresh,
    searchItem,
    currentPage,
    sortClicked,
    cfgApiRequestOptions,
    sortById,
    sortByType,
    selectedFilterCount,
    tableFilter,
  } = devicesState;

  const { getDevices } = deviceCacheAPI(appUuid);
  const { getProxiesByTenant } = fleetProxyAPI();
  const dispatch = useDispatch();
  const { refreshDevicesTableWithoutApi } = useRefreshHook();

  const tableId = 'apps-table';
  const initialFetch = useRef(true);
  const fleetProxyList = useRef([]);
  const enableRnPm =
    devicesRnPmState.contentsDevicesCountRnPm && devicesRnPmState.contentsCollectionsReadRnPm;

  const [error, setError] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [isFetchingDevices, setIsFetchingDevices] = useState(true);

  /* This is used when Search, Sort is activated */
  const [entireDataCache, setEntireDataCache] = useState([]);

  const searchActivated = useMemo(
    () => searchItem != '' || cfgApiRequestOptions.search,
    [searchItem],
  );
  const sortActivated = useMemo(() => sortClicked || sortById != 'uid', [sortClicked, sortById]);
  const filterActivated = useMemo(() => selectedFilterCount > 0, [selectedFilterCount]);

  const { tableHandler } = useTableHandler();
  const { columnConfig } = useTableColumn();
  const { triggerSearch } = useSearch(columnConfig);
  const { triggerSort } = useSort(columnConfig);
  const { triggerFilter } = useFilter(columnConfig);

  const fetchTable = async (deviceTableRefresh) => {
    await apiGetTableResponse(
      deviceTableRefresh == DeviceTableRefreshType.REFRESH_WITH_API_CALL,
    ).then(({ devices }) => {
      setTableColumns(tableId, columnConfig);
      handlePageData(devices, deviceTableRefresh == DeviceTableRefreshType.REFRESH_WITH_API_CALL);
    });
  };

  useEffect(() => {
    const sortInfo = sessionStorage.getItem(tableId + '-sort');
    if (sortInfo) {
      dispatch(storeSortById(sortInfo.split('|')[0]));
      dispatch(storeSortByType(sortInfo.split('|')[1]));
    } else {
      sessionStorage.setItem(tableId + '-sort', 'statusUpdated|descending');
      dispatch(storeSortById('statusUpdated'));
      dispatch(storeSortByType('descending'));
    }
    setIsFetching(true);
    apiGetFleetProxy().finally(() => {
      setIsFetching(false);
    });
  }, []);

  useEffect(() => {
    if (isFetching == false) {
      setIsFetchingDevices(true);
      fetchTable(DeviceTableRefreshType.REFRESH_WITH_API_CALL).finally(() => {
        dispatch(storeDeviceTableRefresh(DeviceTableRefreshType.NONE));
        if (initialFetch.current) initialFetch.current = false;
        setIsFetchingDevices(false);
      });
    }
  }, [isFetching]);

  useEffect(() => {
    if (
      deviceTableRefresh == DeviceTableRefreshType.NONE ||
      (initialFetch.current &&
        deviceTableRefresh == DeviceTableRefreshType.REFRESH_WITHOUT_API_CALL)
    ) {
      return;
    }
    fetchTable(deviceTableRefresh).finally(() => {
      dispatch(storeDeviceTableRefresh(DeviceTableRefreshType.NONE));
    });
  }, [deviceTableRefresh]);

  /*  This is for Pagination
   * when table data is changed without calling API, and reuse saved list
   * search/sort is active, and page changes
   * */
  useEffect(() => {
    if (searchItem == '' && sortById == 'uid') {
      return;
    }

    handlePageData(entireDataCache, false);
  }, [currentPage]);

  useEffect(() => {
    if (initialFetch.current && searchItem == '') {
      return;
    }

    if (searchItem == '' || !!cfgApiRequestOptions.search) {
      dispatch(storeTotalDeviceCountInGroup(entireDataCache.length));
    }

    refreshDevicesTableWithoutApi();
  }, [searchItem]);

  useEffect(() => {
    if (!sortClicked) {
      return;
    }
    handlePageData(entireDataCache, false);
  }, [sortClicked]);

  const getRequestParams = (params) => {
    let returnParams = params;
    if (params.search && params.search.length > 0) {
      returnParams = { ...params, ...fullListOption };
    }
    if (sortClicked) {
      returnParams = { ...params, ...fullListOption };
    }
    if (cfgApiRequestOptions.search) {
      returnParams = { ...cfgApiRequestOptions, ...fullListOption };
    }

    return returnParams;
  };

  const fetchAllDevices = async (_reqParam) => {
    const getDevicesCacheAPI = async (_Id, _offset, _limit) => {
      return await getDevices({
        ..._reqParam,
        offset: _offset,
        limit: _limit,
      });
    };

    const devices = await fetchAllWithLoop(
      getDevicesCacheAPI,
      () => {
        return;
      },
      setError,
      '',
      'deviceCache',
    );
    return { response: devices };
  };
  const apiGetTableResponse = async (isApiCallNeeded) => {
    const reqParam = getRequestParams(cfgApiRequestOptions);

    const fetch = async () => {
      if (isApiCallNeeded) {
        return await fetchAllDevices(reqParam);
      } else {
        return { response: entireDataCache };
      }
    };

    const { response } = await fetch();
    return { devices: response };
  };

  const apiGetFleetProxy = async () => {
    const setFleetProxyConnectionState = (response) => {
      fleetProxyList.current = response;
    };

    return await fetchAllWithLoop(
      getProxiesByTenant,
      setFleetProxyConnectionState,
      setError,
      '',
      'fleetproxy',
    );
  };

  const handlePageData = (data, isApiCall) => {
    let items;
    let processedData;

    if (data !== undefined) {
      items = JSON.parse(JSON.stringify(data));
    }

    items = items?.map((item) => ({
      ...item,
      connectivity: item.connTypes?.includes('Proxy')
        ? fleetProxyList.current
            .find((comp) => item.fleetProxyId.includes(comp.id))
            ?.connectionState.status.toLowerCase() ?? 'online'
        : item.status?.connectionState,
    }));

    const currenTableData = isApiCall ? items : entireDataCache;
    processedData = currenTableData;

    if (sortActivated) {
      const { pagedItems, entireItems, count, valid } = triggerSort(
        currenTableData,
        sortById,
        sortByType,
      );
      if (valid) {
        items = pagedItems;
        processedData = entireItems;
        dispatch(storeTotalDeviceCountInGroup(count));
        setEntireDataCache(entireItems);
      }
      dispatch(storeTableSortClicked(false)); //reset sortClicked
    }

    if (searchActivated || filterActivated) {
      if (filterActivated) {
        const { pagedItems, entireItems, count, valid } = triggerFilter(
          processedData,
          tableFilter,
          currentPage,
        );
        if (valid) {
          items = pagedItems;
          dispatch(storeTotalDeviceCountInGroup(count));
          processedData = entireItems;
        }
      }

      if (searchActivated) {
        const { pagedItems, count, valid } = triggerSearch(processedData, searchItem, currentPage);
        if (valid) {
          items = pagedItems;
          dispatch(storeTotalDeviceCountInGroup(count));
        }
      }
    }

    const res = tableHandler.getTableData(items);
    dispatch(storeTableData(res));
  };

  const enableRender = !isFetching && !isFetchingDevices && enableRnPm;

  const memoRenderComponent = useMemo(() => {
    if (enableRender) {
      console.log('[app-overview] render <DeviceforAppTableTemplate />');
      return (
        <DeviceAppConfigTemplate
          error={error}
          loading={isFetching || isFetchingDevices}
          columnConfig={columnConfig}
        />
      );
    } else {
      return <></>;
    }
  }, [enableRender]);

  return <>{isFetching ? <InitialLoading /> : <>{memoRenderComponent}</>}</>;
};

export default DeviceAppConfigPage;
